import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { transform } from '../../utils/dateFormatted';
import './timer.scss';

const floor = (value: any): number => ~~value;

const GameTimer = ({ time, className, intl: { formatMessage } }) => {
  const [timer, setTimer] = useState(transform(time, 'forTimer'));
  const [currentTime, setCurrentTime] = useState(null);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [timeout, setNewTimeout] = useState(null);
  useEffect(() => {
    if (time) {
      // if(currentTime !== time){
      //   setCurrentTime(time);
      //   clearTimeout(timeout);
      //   clearInterval(currentInterval)
      //   setTimer(transform(time, 'forTimer'));
      //   const date = new Date();
      //   setNewTimeout(setTimeout(() => {
      //     setCurrentInterval(setInterval(() => setTimer(transform(time, 'forTimer')), 60000));
      //     setTimer(transform(time, 'forTimer'));
      //   }, (60 - date.getSeconds()) * 1000));
      // }
      if(timeout){
        clearTimeout(timeout);
      }

      if(currentInterval){
        clearInterval(currentInterval)
      }

      setTimer(transform(time, 'forTimer'));
      const date = new Date();
      setNewTimeout(setTimeout(() => {
        setCurrentInterval(setInterval(() => setTimer(transform(time, 'forTimer')), 60000));
        setTimer(transform(time, 'forTimer'));
      }, (60 - date.getSeconds()) * 1000));
    }
  }, [time]);
  return (
    <div className={className}>
      <div className="timer">
        <div className="title row">
          {formatMessage({
            id: 'REMAINING TIME',
          })}
        </div>

        <div className="row">
          <div className="timer-block">
            <span className="numeral"><span>{timer && floor(timer.days / 10)}</span></span>
            <span className="numeral"><span>{timer && floor(timer.days % 10)}</span></span>
            <div className="days">
              {formatMessage({
                id: 'Days',
              })}
            </div>
          </div>

          <div className="colon">:</div>

          <div className="timer-block">
            <span className="numeral"><span>{timer && floor(timer.hours / 10)}</span></span>
            <span className="numeral"><span>{timer && floor(timer.hours % 10)}</span></span>
            <div className="hours">
              {' '}
              {formatMessage({
                id: 'Hours',
              })}
            </div>
          </div>

          <div className="colon">:</div>

          <div className="timer-block">
            <span className="numeral"><span>{ timer && floor(timer.mins / 10)}</span></span>
            <span className="numeral"><span>{timer && floor(timer.mins % 10)}</span></span>
            <div className="minutes">
              {formatMessage({
                id: 'Minutes',
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(injectIntl(GameTimer));
