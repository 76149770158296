import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import './free-tickets.scss';
import classNames from 'classnames';
import find from 'lodash/find';
import { injectIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import discount from '../../assets/icons/discount.svg';
import LotteriesContext from '../../state/LotteriesContext';
import { transformPrice } from '../../utils/formattes';
import GameTimer from '../../components/GameTimer';
import { newTicket, randomNumbers } from '../../utils/ticketsUtils';
import {getParameterByName} from "../../utils/urlUtils"
import trust from '../../assets/images/trust.png';

const FreeTickets = ({ intl: { formatMessage } }) => {
  const { lotteries } = useContext(LotteriesContext);
  const [tickets, setTickets] = useState([]);
  const [ticketsLeft, setTicketsLeft] = useState(0);
  const translate = (str) => (formatMessage({id: str}))
  const powerBall = lotteries?.find((element) => element.id === 1);

  useEffect(() => {
    setTicketsLeft(randomNumbers(1, 32, 18)[0]);
    const newTickets = localStorage.getItem('free-tickets');

    if (newTickets) {
      setTickets(JSON.parse(newTickets));
    }

    const ref = getParameterByName('ref');
    if(ref){
      sessionStorage.setItem('ref', ref);
    }
    console.log(ref);
  }, []);
  useEffect(() => {
    if (powerBall && !tickets.length) {
      const ticketsArray = [newTicket(powerBall, true), newTicket(powerBall, true), newTicket(powerBall, false, true)];
      const newTickets = localStorage.getItem('free-tickets');

      if (newTickets) {
        setTickets(JSON.parse(newTickets));
      } else {
        setTickets(ticketsArray);
      }
    }
  }, [powerBall]);

  const handleChangeNumber = (ticket, numberIndex, isBonus) => (event) => {
    const newTickets = tickets.map((element) => {
      if (element.id === ticket.id) {
        const key = isBonus ? 'bonusNumbers' : 'mainNumbers';
        const maxCount = isBonus ? 'maxBonus': 'maxNum';
        element[key][numberIndex] = event.target.value > element[maxCount] || event.target.value === '0'? element[key][numberIndex] : event.target.value.replace(/\D/g, '');
        // if (Number(event.target.value === 0)) {
        //   element[key][numberIndex] = null;
        // }
      }
      return element;
    });

    setTickets(newTickets);
  };

  const handleRemoveTicket = (ticket) => (event) => {
    const newTickets = tickets.filter((element) => ticket.id !== element.id);
    setTickets(newTickets);
  };

  const handleAddTicket = () => {
    setTickets([...tickets, newTicket(powerBall, false, true)]);
  };

  const handleRandomTicket = (ticket) => (event) => {
    const newTickets = tickets.map((element) => {
      if (element.id === ticket.id) {
        return {
          ...element,
          mainNumbers: randomNumbers(element.regNumCount, element.maxNum),
          bonusNumbers: randomNumbers(element.bonusNumCount, element.maxBonus),
        };
      }
      return element;
    });

    setTickets(newTickets);
  };
  const checkEmpty = (ticket) => !find(ticket.mainNumbers, (num) => num > 0)
      && !find(ticket.bonusNumbers, (num) => num >= ticket.game.bonusMin);

  const getTotalPrice = () => {
    const filteredPrice = tickets.filter((element) => element.mainNumbers.filter((num) => !!num).length === element.regNumCount && element.bonusNumbers.filter((num) => !!num).length === element.bonusNumCount);
    const computedPrice = filteredPrice.length * powerBall?.prices[0].pricePerLine;
    const freePrice = filteredPrice.length > 1 ? (filteredPrice.length - 1) * powerBall?.prices[0].pricePerLine : 0;
    return {
      computedPrice: computedPrice || 0,
      freePrice: freePrice || 0,
      validElements: filteredPrice.length < 2,
    };
  };

  const handleApplyTickets = () => {
    const filteredPrice = tickets.filter((element) => element.mainNumbers.filter((num) => !!num).length === element.regNumCount && element.bonusNumbers.filter((num) => !!num).length === element.bonusNumCount);
    localStorage.setItem('free-tickets', JSON.stringify(filteredPrice));
    navigate('/free-tickets/checkout-step-2');
  };

  const handleBlur = (ticket, numberIndex, isBonus) => (event) => {
    const newTickets = tickets.map((element) => {
      if (element.id === ticket.id) {
        const key = isBonus ? 'bonusNumbers' : 'mainNumbers';
        const numbersArray = element[key].filter((item) => Number(item) === Number(event.target.value));
        if(numbersArray.length > 1){
          element[key][numberIndex] = '';
        }
        // if(element[key].includes(event.target.value) || element[key].includes(Number(event.target.value))){
        //   element[key][numberIndex] = null;
        //   return element;
        // }
      }
      return element;
    });

    setTickets(newTickets);
  };
  return (
    <div className="free-tickets-wrapper">
      <Helmet>
        <title>
          Lotteroo | {translate('building the future of lottery')}
        </title>
      </Helmet>
      <div className="wrapper">
        <div className="content">
          <div className="game-block">
            <div className="left">
              <div className="img">
                <img src={powerBall?.logoImgUrl} alt="" width={100} />
              </div>
              <div className="info">
                <div className="text">{translate('Next Jackpot')}</div>
                <div className="stake">{transformPrice(powerBall?.jackpot, 'amountWithSpaces')}</div>
              </div>
            </div>
            <div className="right timer">
              <GameTimer time={powerBall?.endBuingTime} className="bright" />
            </div>
          </div>
          <div className="page-content">
            <div className="main-title">
              {powerBall ? <>{translate('Only')}
                {' '}
                <span>{ticketsLeft}</span>
                {' '}
                {translate('Free Tickets Left')}
                </>: <span className={'placeholder-data'} style={{
                width: '100%',
                height: 50
              }}></span>}
            </div>
            <div className="subtitle">
              {powerBall ? <><span>{100 - ticketsLeft}</span>
                {' '}
                {translate('Free Tickets Claimed Today')}</>: <span className={'placeholder-data'} style={{
                width: '100%',
                height: 30
              }}></span>}
            </div>
            <div className="purple-block">
              <div className="left-part">
                <p>
                  {translate('One time offer')}
                  {' '}
                  <strong>{translate('50% off')}</strong>
                  .
                </p>
              </div>
              <div className="right-part">
                <img src={discount} alt="" />
              </div>
            </div>
            <div className="tickets">
              <div className="title">
                {powerBall ? <span>{translate('Pick')} {powerBall?.regNumCount} {translate('numbers from 1 -')} {powerBall?.numberMax} {translate('and')} {powerBall?.bonusNumCount}  {translate('and')} {translate('Powerball from')} {powerBall?.bonusMin} - {powerBall?.bonusMax}</span>: <span className={'placeholder-data'} style={{
                    width: '100%',
                    height: 30
                }}></span>}
              </div>
              <ul>
                {tickets.length ? tickets.map((element, index) => {
                  const isEmpty = checkEmpty(element);
                  const { pricePerLine }: any = find(element.prices, { quantity: 1 });
                  return (
                    <li
                      key={element.id}
                      className={classNames({
                        free: index === 0,
                      })}
                    >
                      <div className="inner">
                        <div className="btn">
                          {isEmpty ? (
                            <button className="lucky-pick" onClick={handleRandomTicket(element)}>
                              {translate('Lucky Pick')}
                            </button>
                          ) : (
                            <button className="remove" onClick={handleRemoveTicket(element)}>
                              {translate('Remove')}
                            </button>
                          )}
                        </div>
                        <div className="picked-numbers">
                          <ul>
                            {element.mainNumbers.map((num, numIndex) => (
                              <li key={numIndex} className="empty">
                                <input type="text" value={num} className="input" onChange={handleChangeNumber(element, numIndex)} maxLength={2} onBlur={handleBlur(element, numIndex)}/>
                              </li>
                            ))}

                            {element.bonusNumbers.map((num, numIndex) => (
                              <li key={numIndex} className="number selected bonus">
                                <input type="text" value={num} className="input" onChange={handleChangeNumber(element, numIndex, true)} maxLength={2} onBlur={element, numIndex, true}/>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="price">
                          {index === 0 ? <span className="for-free">Free</span> : (
                            <>
                              <div className="price-full">
                                {transformPrice(pricePerLine, 'forCart')}
                              </div>
                              <div className="price-yours">
                                {transformPrice(pricePerLine / 2, 'forCart')}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                }) :  <li className={'free free-no-lines'}>
                  <div className="inner">
                    <div className={'no-lines'}>
                      {translate('There are no lines yet')}
                    </div>
                  </div>
                </li>}
              </ul>

              <div className="add-more">
                <button onClick={handleAddTicket}>
                  {translate('Add more lines')}
                </button>
              </div>
            </div>
            <div className="total">
              <div className="price-block">
                <div className="old">
                  {translate('was')}
                  {' '}
                  {transformPrice(getTotalPrice().computedPrice, 'forCart')}
                </div>
                <div className="new">
                  {translate('Now')}
                  {' '}
                  <span>{transformPrice(getTotalPrice().freePrice / 2, 'forCart')}</span>
                </div>
              </div>
            </div>
            <div className="play-now">
              <button disabled={getTotalPrice().validElements} onClick={handleApplyTickets}>
                        {translate('Play Now')}
              </button>
            </div>
            <div className="certificates">
              <img src={trust} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(FreeTickets);
