import sortBy from "lodash/sortBy"

export const randomNumbers = (numberCount: number, numberMax: number, numberMin = 1, emptyNumbers): number[] => {
  const arr = [];
  if (emptyNumbers){
    while (arr.length < numberCount) {
      arr.push(null)
    }
  }
  while (arr.length < numberCount) {
    const randomNumber = Math.floor(numberMin + Math.random() * (numberMax + 1 - numberMin));
    if (arr.indexOf(randomNumber) > -1) {
      continue;
    }
    arr.push(randomNumber);
  }

  return arr;
};
export const newTicket = (gameData, isRandom = false, emptyNumbers = false) => {
  let mainNumbers = [];
  let bonusNumbers = [];
  if (isRandom) {
    mainNumbers = randomNumbers(gameData.regNumCount, gameData.numberMax);
    bonusNumbers = randomNumbers(gameData.bonusNumCount, gameData.bonusMax, gameData.bonusMin);
  }

  if(emptyNumbers) {
    mainNumbers = randomNumbers(gameData.regNumCount, gameData.numberMax, 1, emptyNumbers);
    bonusNumbers = randomNumbers(gameData.bonusNumCount, gameData.bonusMax, gameData.bonusMin, emptyNumbers);
  }

  const numbers: number[] = [];
  for (let i = 1; i <= gameData.numberMax; ++i) {
    numbers.push(i);
  }

  const bonuses: number[] = [];
  for (let i = gameData.bonusMin; i <= gameData.bonusMax; ++i) {
    bonuses.push(i);
  }

  const ticketData = ({
    id: Math.random().toString(36).slice(2),
    idGame: gameData.id,
    game: gameData,
    mainNumbers: sortBy(mainNumbers),
    bonusNumbers: sortBy(bonusNumbers),
    duration: gameData.duration || 1,
    maxNum: gameData.numberMax,
    maxBonus: gameData.bonusMax,
    regNumCount: gameData.regNumCount,
    bonusNumCount: gameData.bonusNumCount,
    minBonus: gameData.bonusMin,
    numbers,
    bonuses,
    prices: gameData.prices,
  });

  return ticketData;
};
